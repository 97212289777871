#loggingForm {
  display: flex;
  flex-direction: column;
  padding: 10px 50px 20px 50px;
}

#loggingButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  #loggingView {
    padding: 0px;
  }
  #loggingForm {
    padding: 10px 10px 20px 10px;
  }
}
