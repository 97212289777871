#submissionTrackingForm {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 20px 15px;
}

#submissionTrackingButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  #submissionTrackingView {
    padding: 0px;
  }
}
