/* CSS used to generate the belt icons */
.white {
  background: #f3f3f3;
  color: black;
}
.blue {
  background: #4a86e8;
  color: white;
}
.purple {
  background: #9900ff;
  color: white;
}
.brown {
  background: #783f04;
  color: white;
}
.black {
  background: black;
  color: white;
}

.beltTable {
  display: grid;
  padding: 10px;
  grid-template-columns: 5px min-content 5px min-content min-content min-content [totalBeltMatTime-start] min-content [totalBeltMatTime-end totalBeltYears-start] min-content [totalBeltYears-end];
  grid-template-rows: repeat(26, auto);
  grid-column-gap: 0px;
  grid-row-gap: 2px;
  background: lightgrey;
  justify-content: center;
  border-radius: 5px;
}

.startBelt {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.stripeDiv {
  display: flex;
  width: 30px;
  padding-left: 3px;
  background: black;
}

.black.stripeDiv {
  background: red;
}

.stripe {
  background: white;
  width: 4px;
  margin-right: 1px;
  margin-left: 1px;
}

.beltText {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 10px;
  white-space: nowrap;
  text-align: center;
  padding-left: 10px;
}

.endBelt {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.totalBeltMatTime,
.totalBeltYears {
  white-space: nowrap;
  margin-left: 5px;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalBeltMatTimeContent {
  grid-column-start: totalBeltMatTime-start;
  grid-column-end: totalBeltMatTime-end;
}

.totalBeltYearsContent {
  grid-column-start: totalBeltYears-start;
  grid-column-end: totalBeltYears-end;
}

.white.totalBeltMatTimeContent,
.white.totalBeltYearsContent {
  grid-row: 2 / span 5;
}

.blue.totalBeltMatTimeContent,
.blue.totalBeltYearsContent {
  grid-row: 7 / span 5;
}

.purple.totalBeltMatTimeContent,
.purple.totalBeltYearsContent {
  grid-row: 12 / span 5;
}
.brown.totalBeltMatTimeContent,
.brown.totalBeltYearsContent {
  grid-row: 17 / span 5;
}
.black.totalBeltMatTimeContent,
.black.totalBeltYearsContent {
  grid-row: 22 / span 5;
}

.stripe0.stripeDiv > .stripe:nth-child(n + 1) {
  visibility: hidden;
}

.stripe1.stripeDiv > .stripe:nth-child(n + 2) {
  visibility: hidden;
}

.stripe2.stripeDiv > .stripe:nth-child(n + 3) {
  visibility: hidden;
}

.stripe3.stripeDiv > .stripe:nth-child(n + 4) {
  visibility: hidden;
}

.stripe4.stripeDiv > .stripe:nth-child(n + 5) {
  visibility: hidden;
}

@media (max-width: 600px) {
  .beltTable {
    font-size: 7pt;
  }
  .beltText {
    padding-right: 1px;
    padding-left: 1px;
  }
}
