#setMovesSkillAreaButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  #setMovesSkillAreaView {
    padding: 0px;
  }
}
