#dailyCalendarHeatMapsGrid {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1024px) {
  #dailyCalendarHeatMapsGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 414px) {
  #dailyCalendarHeatMapsGrid {
    padding: 10px;
  }
}
